import { defineComponent, ref } from 'vue';
import BaseIcon from '../atoms/BaseIcon.vue';
export default defineComponent({
    components: { BaseIcon },
    name: 'FooterComponent',
    setup() {
        const showSocialShare = ref(false);
        return { showSocialShare };
    },
});
